import styled from "styled-components";

export const RequestModalMain = styled.div`

`;
export const RequestModalPadding = styled.div`

`;
export const RequestModalInner = styled.div`

`;
