//Account constants
export const IS_FETCHING_ACCOUNTS = "IS_FETCHING_ACCOUNTS";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const HAS_REQUESTED_ACCOUNTS = "HAS_REQUESTED_ACCOUNTS";
export const OPEN_CREATE_ACCOUNT_MODAL = "OPEN_CREATE_ACCOUNT_MODAL";
export const CLOSE_CREATE_ACCOUNT_MODAL = "CLOSE_CREATE_ACCOUNT_MODAL";
export const OPEN_PROFESSIONAL_PORTAL_ASSISTANCE_MODAL = "OPEN_PROFESSIONAL_PORTAL_ASSISTANCE_MODAL";
export const CLOSE_PROFESSIONAL_PORTAL_ASSISTANCE_MODAL = "CLOSE_PROFESSIONAL_PORTAL_ASSISTANCE_MODAL";
export const GET_ACCOUNT_CUSTOMER = "GET_ACCOUNT_CUSTOMER";
export const IS_FETCHING_CUSTOMER = "IS_FETCHING_CUSTOMER";
export const HAS_REQUESTED_ACCOUNT_CUSTOMER = "HAS_REQUESTED_ACCOUNT_CUSTOMER";
export const OPEN_ADD_NEW_USER_MODAL = "OPEN_ADD_NEW_USER_MODAL";
export const CLOSE_ADD_NEW_USER_MODAL = "CLOSE_ADD_NEW_USER_MODAL";
export const UPDATE_PARTNER_CONVERSION_STATE = "UPDATE_PARTNER_CONVERSION_STATE";
export const CLEAR_PARTNER_CONVERSION_STATE = "CLEAR_PARTNER_CONVERSION_STATE";
export const OPEN_PAYMENT_METHODS_MODAL = "OPEN_PAYMENT_METHODS_MODAL";
export const CLOSE_PAYMENT_METHODS_MODAL = "CLOSE_PAYMENT_METHODS_MODAL";
export const GET_ACCOUNT_SUBSCRIPTIONS = "GET_ACCOUNT_SUBSCRIPTIONS";
export const HAS_REQUESTED_CUSTOMER_SUBSCRIPTIONS = "HAS_REQUESTED_CUSTOMER_SUBSCRIPTIONS";
export const IS_FETCHING_SUBSCRIPTIONS = "IS_FETCHING_SUBSCRIPTIONS";
export const CHANGE_ACCOUNTS_TAB = "CHANGE_ACCOUNTS_TAB";
export const UPDATE_ACCOUNT_RECORD = "UPDATE_ACCOUNT_RECORD";
export const UPDATE_PARTNER_ACCOUNT_RECORD = "UPDATE_PARTNER_ACCOUNT_RECORD";
export const GET_ACCOUNT_TRANSACTIONS = "GET_ACCOUNT_TRANSACTIONS";
export const HAS_REQUESTED_CUSTOMER_TRANSACTIONS = "HAS_REQUESTED_CUSTOMER_TRANSACTIONS";
export const IS_FETCHING_TRANSACTIONS = "IS_FETCHING_TRANSACTIONS";
export const UPDATE_LOGGEDIN_USER = "UPDATE_LOGGEDIN_USER";
export const UPDATE_CURRENT_ACCOUNT = "UPDATE_CURRENT_ACCOUNT";

// Plans contacts
export const GET_ACTIVE_USER_PLANS = "GET_ACTIVE_USER_PLANS";
export const GET_ACTIVE_PARTNER_PLANS = "GET_ACTIVE_PARTNER_PLANS";
export const IS_FETCHING_ACTIVE_PARTNER_PLANS = "IS_FETCHING_ACTIVE_PARTNER_PLANS";
export const HAS_REQUESTED_ACTIVE_PARTNER_PLANS = "HAS_REQUESTED_ACTIVE_PARTNER_PLANS";
export const IS_FETCHING_ACTIVE_USER_PLANS = "IS_FETCHING_ACTIVE_USER_PLANS";
export const HAS_REQUESTED_ACTIVE_USER_PLANS = "HAS_REQUESTED_ACTIVE_USER_PLANS";

// Login constants
export const IS_LOGGING_IN = "IS_LOGGING_IN";
export const BULK_UPDATE_LOGIN = "BULK_UPDATE_LOGIN";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const CLEAR_LOGIN = "CLEAR_LOGIN";

// Authentication constants
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_REGISTERED = "IS_REGISTERED";

// Navigation constants
export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const TOGGLE_MOBILE_NAVIGATION_MENU = "TOGGLE_MOBILE_NAVIGATION_MENU";

// Session constants
export const SET_SESSION_ACCOUNT = "SET_SESSION_ACCOUNT";
export const UPDATE_ZENDESK_STATE = "UPDATE_ZENDESK_STATE";
export const CLOSE_INSTALL_PROMPT_MODAL = "CLOSE_INSTALL_PROMPT_MODAL";
export const OPEN_INSTALL_PROMPT_MODAL = "OPEN_INSTALL_PROMPT_MODAL";
export const UPDATE_SESSION = "UPDATE_SESSION";

// Relationship constants
export const GET_RELATIONSHIPS = "GET_RELATIONSHIPS";
export const CREATE_NEW_RELATIONSHIP = "CREATE_NEW_RELATIONSHIP";
export const UPDATE_RELATIONSHIP = "UPDATE_RELATIONSHIP";
export const DELETE_RELATIONSHIP = "DELETE_RELATIONSHIP";
export const OPEN_CREATE_RELATIONSHIP_MODAL = "OPEN_CREATE_RELATIONSHIP_MODAL";
export const CLOSE_CREATE_RELATIONSHIP_MODAL = "CLOSE_CREATE_RELATIONSHIP_MODAL";
export const HAS_REQUESTED_RELATIONSHIPS = "HAS_REQUESTED_RELATIONSHIPS";
export const IS_FETCHING_RELATIONSHIPS = "IS_FETCHING_RELATIONSHIPS";

// Document constants
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const UPDATE_DOCUMENTS_VIEW = "UPDATE_DOCUMENTS_VIEW";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const OPEN_CREATE_DOCUMENT_MODAL = "OPEN_CREATE_DOCUMENT_MODAL";
export const CLOSE_CREATE_DOCUMENT_MODAL = "CLOSE_CREATE_DOCUMENT_MODAL";
export const IS_FETCHING_DOCUMENTS = "IS_FETCHING_DOCUMENTS";
export const HAS_FETCHED_DOCUMENTS = "HAS_FETCHED_DOCUMENTS";
export const UPDATE_DOCUMENT_USAGE = "UPDATE_DOCUMENT_USAGE";

// Finance constants
export const GET_ACCOUNT_GRANTOR_ASSETS = "GET_ACCOUNT_GRANTOR_ASSETS";
export const GET_ACCOUNT_BENEFICIARY_ASSETS = "GET_ACCOUNT_BENEFICIARY_ASSETS";
export const GET_ACCOUNT_INCOME = "GET_ACCOUNT_INCOME";
export const GET_ACCOUNT_BENEFITS = "GET_ACCOUNT_BENEFITS";
export const GET_ACCOUNT_BUDGETS = "GET_ACCOUNT_BUDGET";
export const CREATE_BENEFICIARY_ASSET_RECORD = "CREATE_BENEFICIARY_ASSET_RECORD";
export const CREATE_GRANTOR_ASSET_RECORD = "CREATE_GRANTOR_ASSET_RECORD";
export const CREATE_INCOME_RECORD = "CREATE_INCOME_RECORD";
export const CREATE_BENEFIT_RECORD = "CREATE_BENEFIT_RECORD";
export const CREATE_BUDGET_RECORD = "CREATE_BUDGET_RECORD";
export const CREATE_GRANTOR_ASSET_RECORDS = "CREATE_GRANTOR_ASSET_RECORDS";
export const CREATE_BENEFICIARY_ASSET_RECORDS = "CREATE_BENEFICIARY_ASSET_RECORDS";
export const UPDATE_BENEFICIARY_ASSET_RECORD = "UPDATE_BENEFICIARY_ASSET_RECORD";
export const UPDATE_GRANTOR_ASSET_RECORD = "UPDATE_GRANTOR_ASSET_RECORD";
export const UPDATE_INCOME_RECORD = "UPDATE_INCOME_RECORD";
export const UPDATE_BENEFIT_RECORD = "UPDATE_BENEFIT_RECORD";
export const UPDATE_BUDGET_RECORD = "UPDATE_BUDGET_RECORD";
export const DELETE_BENEFICIARY_ASSET_RECORD = "DELETE_BENEFICIARY_ASSET_RECORD";
export const DELETE_GRANTOR_ASSET_RECORD = "DELETE_GRANTOR_ASSET_RECORD";
export const DELETE_INCOME_RECORD = "DELETE_INCOME_RECORD";
export const DELETE_BENEFIT_RECORD = "DELETE_BENEFIT_RECORD";
export const DELETE_BUDGET_RECORD = "DELETE_BUDGET_RECORD";
export const OPEN_CREATE_BENEFICIARY_ASSET_MODAL = "OPEN_CREATE_BENEFICIARY_ASSET_MODAL";
export const OPEN_CREATE_GRANTOR_ASSET_MODAL = "OPEN_CREATE_GRANTOR_ASSET_MODAL";
export const CLOSE_CREATE_GRANTOR_ASSET_MODAL = "CLOSE_CREATE_GRANTOR_ASSET_MODAL";
export const CLOSE_CREATE_BENEFICIARY_ASSET_MODAL = "CLOSE_CREATE_BENEFICIARY_ASSET_MODAL";
export const OPEN_CREATE_INCOME_MODAL = "OPEN_CREATE_INCOME_MODAL";
export const CLOSE_CREATE_INCOME_MODAL = "CLOSE_CREATE_INCOME_MODAL";
export const OPEN_CREATE_BENEFIT_MODAL = "OPEN_CREATE_BENEFIT_MODAL";
export const CLOSE_CREATE_BENEFIT_MODAL = "CLOSE_CREATE_BENEFIT_MODAL";
export const OPEN_CREATE_BUDGET_MODAL = "OPEN_CREATE_BUDGET_MODAL";
export const CLOSE_CREATE_BUDGET_MODAL = "CLOSE_CREATE_BUDGET_MODAL";
export const OPEN_PLAID_LINK_MODAL = "OPEN_PLAID_LINK_MODAL";
export const CLOSE_PLAID_LINK_MODAL = "CLOSE_PLAID_LINK_MODAL";
export const SET_ACTIVE_FINANCE_TAB = "SET_ACTIVE_FINANCE_TAB";
export const IS_FETCHING_GRANTOR_ASSETS = "IS_FETCHING_GRANTOR_ASSETS";
export const IS_FETCHING_BENEFICIARY_ASSETS = "IS_FETCHING_BENEFICIARY_ASSETS";
export const IS_FETCHING_INCOME = "IS_FETCHING_INCOME";
export const IS_FETCHING_BENEFITS = "IS_FETCHING_BENEFITS";
export const IS_FETCHING_BUDGETS = "IS_FETCHING_BUDGETS";
export const HAS_REQUESTED_GRANTOR_ASSETS = "HAS_REQUESTED_GRANTOR_ASSETS";
export const HAS_REQUESTED_BENEFICIARY_ASSETS = "HAS_REQUESTED_BENEFICIARY_ASSETS";
export const HAS_REQUESTED_INCOME = "HAS_REQUESTED_INCOME";
export const HAS_REQUESTED_BENEFITS = "HAS_REQUESTED_BENEFITS";
export const HAS_REQUESTED_BUDGETS = "HAS_REQUESTED_BUDGETS";

// MYTO constants
export const RUN_MYTO_SIMULATION = "RUN_MYTO_SIMULATION";
export const UPDATE_MYTO_CONFIG = "UPDATE_MYTO_CONFIG";
export const ADD_MYTO_ASSET = "ADD_MYTO_ASSET";
export const UPDATE_MYTO_ASSET = "UPDATE_MYTO_ASSET";
export const DELETE_MYTO_ASSET = "DELETE_MYTO_ASSET";
export const ADD_MYTO_INCOME = "ADD_MYTO_INCOME";
export const UPDATE_MYTO_INCOME = "UPDATE_MYTO_INCOME";
export const DELETE_MYTO_INCOME = "DELETE_MYTO_INCOME";
export const ADD_MYTO_BENEFIT = "ADD_MYTO_BENEFIT";
export const UPDATE_MYTO_BENEFIT = "UPDATE_MYTO_BENEFIT";
export const DELETE_MYTO_BENEFIT = "DELETE_MYTO_BENEFIT";
export const ADD_MYTO_EXPENSE = "ADD_MYTO_EXPENSE";
export const UPDATE_MYTO_EXPENSE = "UPDATE_MYTO_EXPENSE";
export const DELETE_MYTO_EXPENSE = "DELETE_MYTO_EXPENSE";
export const IMPORT_MYTO_FINANCES = "IMPORT_MYTO_FINANCES";
export const SWITCH_MYTO_VIEW = "SWITCH_MYTO_VIEW";
export const GET_MYTO_SIMULATIONS = "GET_MYTO_SIMULATIONS";
export const UPDATE_MYTO_SIMULATION = "UPDATE_MYTO_SIMULATION";
export const DELETE_MYTO_SIMULATION = "DELETE_MYTO_SIMULATION";
export const IS_FETCHING_MYTO_SIMULATIONS = "IS_FETCHING_MYTO_SIMULATIONS";
export const HAS_REQUESTED_MYTO_SIMULATIONS = "HAS_REQUESTED_MYTO_SIMULATIONS";
export const CHANGE_MYTO_STEP = "CHANGE_MYTO_STEP";
export const OPEN_MYTO_SIMULATION = "OPEN_MYTO_SIMULATION";
export const CLOSE_MYTO_SIMULATION = "CLOSE_MYTO_SIMULATION";
export const LOAD_SIMULATION = "LOAD_SIMULATION";
export const CLEAR_MYTO_CALCULATOR = "CLEAR_MYTO_CALCULATOR";
export const RETRY_MYTO = "RETRY_MYTO";
export const RESET_MYTO_RETRYS = "RESET_MYTO_RETRYS";

// Provider constants
export const GET_PROVIDERS = "GET_PROVIDERS";
export const UPDATE_PROVIDERS = "UPDATE_PROVIDERS";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const OPEN_CREATE_PROVIDER_MODAL = "OPEN_CREATE_PROVIDER_MODAL";
export const CLOSE_CREATE_PROVIDER_MODAL = "CLOSE_CREATE_PROVIDER_MODAL";
export const IS_FETCHING_PROVIDERS = "IS_FETCHING_PROVIDERS";
export const HAS_REQUESTED_PROVIDERS = "HAS_REQUESTED_PROVIDERS";

// Signup constants
export const UPDATE_SIGNUP_STATE = "UPDATE_SIGNUP_STATE";
export const CLEAR_SIGNUP_STATE = "CLEAR_SIGNUP_STATE";
export const OPEN_PARTNER_PLAN_OVERLAY = "OPEN_PARTNER_PLAN_OVERLAY";
export const CLOSE_PARTNER_PLAN_OVERLAY = "CLOSE_PARTNER_PLAN_OVERLAY";

// Misc. constants
export const CLEAR_ALL = "CLEAR_ALL";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const IS_ACTIVE = "IS_ACTIVE";
export const IS_IDLE = "IS_IDLE";
export const USER_DID_ACTION = "USER_DID_ACTION";
export const UPDATE_APPLICATION_STATUS = "UPDATE_APPLICATION_STATUS";
export const IS_SWITCHING = "IS_SWITCHING";
export const CHANGE_SETTINGS_TAB = "CHANGE_SETTINGS_TAB";
export const CHANGE_FORM_SLIDE = "CHANGE_FORM_SLIDE";
export const CHANGE_STEP_COMPLETION = "CHANGE_STEP_COMPLETION";
export const CLEAR_MULTI_PART_FORM = "CLEAR_MULTI_PART_FORM";

// Survey constants
export const GET_SURVEYS = "GET_SURVEYS";
export const GET_SURVEY = "GET_SURVEY";
export const SET_SURVEY = "SET_SURVEY";
export const CLOSE_SURVEY = "CLOSE_SURVEY";
export const IS_FETCHING_SURVEYS = "IS_FETCHING_SURVEYS";
export const IS_FETCHING_SURVEY = "IS_FETCHING_SURVEY";
export const HAS_REQUESTED_SURVEYS = "HAS_REQUESTED_SURVEYS";
export const UPDATE_SURVEY_LOADING_STATE = "UPDATE_SURVEY_LOADING_STATE";
export const RETRY_SURVEY = "RETRY_SURVEY";
export const RESET_RETRYS = "RESET_RETRYS";
export const UPDATE_SURVEY_STATUS = "UPDATE_SURVEY_STATUS";

// Request constants
export const GET_SERVICE_REQUESTS = "GET_SERVICE_REQUESTS";
export const UPDATE_SERVICE_REQUESTS = "UPDATE_SERVICE_REQUESTS";
export const DISPATCH_REQUEST = "DISPATCH_REQUEST";
export const OPEN_REQUEST_MODAL = "OPEN_REQUEST_MODAL";
export const CLOSE_REQUEST_MODAL = "CLOSE_REQUEST_MODAL";
export const OPEN_TICKET_MODAL = "OPEN_TICKET_MODAL";
export const CLOSE_TICKET_MODAL = "CLOSE_TICKET_MODAL";
export const IS_FETCHING_REQUESTS = "IS_FETCHING_REQUESTS";
export const HAS_REQUESTED_SERVICE_REQUESTS = "HAS_REQUESTED_SERVICE_REQUESTS";

// Notification constants
export const SET_NOTIFICATION_SETTINGS = "SET_NOTIFICATION_SETTINGS";
export const IS_FETCHING_NOTIFICATION_SETTINGS = "IS_FETCHING_NOTIFICATION_SETTINGS";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// Security question constants
export const GET_SECURITY_QUESTIONS = "GET_SECURITY_QUESTIONS";
export const GET_SECURITY_QUESTION_RESPONSES = "GET_SECURITY_QUESTION_RESPONSES";
export const CREATE_SECURITY_QUESTION_RESPONSE = "CREATE_SECURITY_QUESTION_RESPONSE";
export const IS_FETCHING_SECURITY_QUESTIONS = "IS_FETCHING_SECURITY_QUESTIONS";
export const IS_FETCHING_SECURITY_RESPONSES = "IS_FETCHING_SECURITY_RESPONSES";
export const HAS_REQUESTED_SECURITY_QUESTIONS = "HAS_REQUESTED_SECURITY_QUESTIONS";
export const HAS_REQUESTED_SECURITY_QUESTION_RESPONSES = "HAS_REQUESTED_SECURITY_QUESTION_RESPONSES";

// Tour constants
export const SHOW_TOUR = "SHOW_TOUR";
export const HIDE_TOUR = "HIDE_TOUR";

// PDF constants
export const CONVERT_MARKDOWN = "CONVERT_MARKDOWN";
export const CLOSE_PDF_MODAL = "CLOSE_PDF_MODAL";

// Schedule constants
export const GET_SCHEDULE_EVENTS = "GET_SCHEDULE_EVENTS";
export const OPEN_SCHEDULE_MODAL = "OPEN_SCHEDULE_MODAL";
export const CLOSE_SCHEDULE_MODAL = "CLOSE_SCHEDULE_MODAL";
export const OPEN_VIEW_SCHEDULE_MODAL = "OPEN_VIEW_SCHEDULE_MODAL";
export const CLOSE_VIEW_SCHEDULE_MODAL = "CLOSE_VIEW_SCHEDULE_MODAL";
export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENTS = "ADD_EVENTS";
export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENTS = "DELETE_EVENTS";
export const EDIT_EVENT = "EDIT_EVENT";
export const EDIT_EVENTS = "EDIT_EVENTS";
export const IS_FETCHING_EVENTS = "IS_FETCHING_EVENTS";
export const HAS_REQUESTED_EVENTS = "HAS_REQUESTED_EVENTS";

// Medication constants
export const GET_MEDICATIONS = "GET_MEDICATIONS";
export const OPEN_MEDICATIONS_MODAL = "OPEN_MEDICATIONS_MODAL";
export const CLOSE_MEDICATIONS_MODAL = "CLOSE_MEDICATIONS_MODAL";
export const ADD_MEDICATION = "ADD_MEDICATION";
export const DELETE_MEDICATION = "DELETE_MEDICATION";
export const EDIT_MEDICATION = "EDIT_MEDICATION";
export const IS_FETCHING_MEDICATIONS = "IS_FETCHING_MEDICATIONS";
export const HAS_REQUESTED_MEDICATIONS = "HAS_REQUESTED_MEDICATIONS";

// Customer Service constants
export const IS_FETCHING_CORE_SETTINGS = "IS_FETCHING_CORE_SETTINGS";
export const HAS_REQUESTED_CORE_SETTINGS = "HAS_REQUESTED_CORE_SETTINGS";
export const GET_CORE_SETTINGS = "GET_CORE_SETTINGS";
export const CLEAR_CUSTOMER_SUPPORT = "CLEAR_CUSTOMER_SUPPORT";
export const UPDATE_APP_VERSION = "UPDATE_APP_VERSION";

// Class Marker constants
export const GET_QUIZ_RESPONSES = "GET_QUIZ_RESPONSES";
export const IS_FETCHING_QUIZ_RESPONSES = "IS_FETCHING_QUIZ_RESPONSES";
export const HAS_REQUESTED_QUIZ_RESPONSES = "HAS_REQUESTED_QUIZ_RESPONSES";
export const GET_QUIZ_RESPONSE = "GET_QUIZ_RESPONSE";
export const OPEN_QUIZ = "OPEN_QUIZ";
export const CLOSE_QUIZ = "CLOSE_QUIZ";
export const OPEN_QUIZ_VIDEO = "OPEN_QUIZ_VIDEO";
export const CLOSE_QUIZ_VIDEO = "CLOSE_QUIZ_VIDEO";
export const CREATE_PARTNER_RESPONSE = "CREATE_PARTNER_RESPONSE";
export const UPDATE_PARTNER_RESPONSE = "UPDATE_PARTNER_RESPONSE";
export const OPEN_PROCTOR_FORM = "OPEN_PROCTOR_FORM";
export const CLOSE_PROCTOR_FORM = "CLOSE_PROCTOR_FORM";
export const OPEN_STUDENT_ATTESTATION = "OPEN_STUDENT_ATTESTATION";
export const CLOSE_STUDENT_ATTESTATION = "CLOSE_STUDENT_ATTESTATION";
export const OPEN_CERTIFICATE_MODAL = "OPEN_CERTIFICATE_MODAL";
export const CLOSE_CERTIFICATE_MODAL = "CLOSE_CERTIFICATE_MODAL";

// Hello Sign constants
export const UPDATE_REQUEST_ID = "UPDATE_REQUEST_ID";
export const UPDATE_DOWNLOAD_LINK = "UPDATE_DOWNLOAD_LINK";
export const UPDATE_CONTRACT_STATE = "UPDATE_CONTRACT_STATE";

// Partner constants
export const OPEN_CONVERT_TO_PARTNER_MODAL = "OPEN_CONVERT_TO_PARTNER_MODAL";
export const CLOSE_CONVERT_TO_PARTNER_MODAL = "CLOSE_CONVERT_TO_PARTNER_MODAL";
export const GET_ORGANIZATION_PARTNERS = "GET_ORGANIZATION_PARTNERS";
export const IS_FETCHING_ORGANIZATION_PARTNERS = "IS_FETCHING_ORGANIZATION_PARTNERS";
export const HAS_FETCHED_ORGANIZATION_PARTNERS = "HAS_FETCHED_ORGANIZATION_PARTNERS";
export const OPEN_PARTNER_LOGO_MODAL = "OPEN_PARTNER_LOGO_MODAL";
export const CLOSE_PARTNER_LOGO_MODAL = "CLOSE_PARTNER_LOGO_MODAL";

// Partner Registration constants
export const BULK_UPDATE_PARTNER_REGISTRATION = "BULK_UPDATE_PARTNER_REGISTRATION";
export const UPDATE_PARTNER_REGISTRATION = "UPDATE_PARTNER_REGISTRATION";
export const CLEAR_PARTNER_REGISTRATION = "CLEAR_PARTNER_REGISTRATION";

// Client Registration constants
export const BULK_UPDATE_CLIENT_REGISTRATION = "BULK_UPDATE_CLIENT_REGISTRATION";
export const UPDATE_CLIENT_REGISTRATION = "UPDATE_CLIENT_REGISTRATION";
export const CLEAR_CLIENT_REGISTRATION = "CLEAR_CLIENT_REGISTRATION";
export const IS_SIGNING_UP = "IS_SIGNING_UP";

// Product constants
export const IS_FETCHING_PRODUCTS = "IS_FETCHING_PRODUCTS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const HAS_REQUESTED_PRODUCTS = "HAS_REQUESTED_PRODUCTS";

// Message constants
export const GET_MESSAGES = "GET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const OPEN_MESSAGE = "OPEN_MESSAGE";
export const CLOSE_MESSAGE = "CLOSE_MESSAGE";
export const IS_FETCHING_MESSAGES = "IS_FETCHING_MESSAGES";
export const HAS_REQUESTED_MESSAGES = "HAS_REQUESTED_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

// CE constants
export const GET_CE_CONFIGS = "GET_CE_CONFIGS";
export const IS_FETCHING_CE_CONFIGS = "IS_FETCHING_CE_CONFIGS";
export const HAS_REQUESTED_CE_CONFIGS = "HAS_REQUESTED_CE_CONFIGS";
export const GET_CE_COURSES = "GET_CE_COURSES";
export const IS_FETCHING_CE_COURSES = "IS_FETCHING_CE_COURSES";
export const HAS_REQUESTED_CE_COURSES = "HAS_REQUESTED_CE_COURSES";